import React from "react";
import photo from "src/images/community/education-resources-3.jpg";

/**
 *  Info module on Education main page
 */
class EducationInfo extends React.Component {
  render() {
    return (
      <section className="info info--grants gutter-rt">
        <img src={photo} alt="" className="media__asset info__img education-info-img" />
        <div className="gutter">    
          <h2 className="heading heading--med info__heading line">Educating a Skilled Workforce</h2>
          <p className="intro">The Port of Long Beach is committed to creating and supporting programs that advance development of a skilled workforce in international trade, goods movement and related professions. In 2018, the Port became the first industry partner to join The Long Beach College Promise. The Promise serves as a bridge between Long Beach educational institutions and the international trade sector.</p>

          <p className="intro">Strengthening the Port’s partnerships with the Long Beach Unified School District, Long Beach City College and California State University, Long Beach, is key to educating students at all levels about the importance of the Port and international trade and creating a workforce pipeline to related industries by providing learning and training opportunities for students and educators. Port programs also support tools, materials and experiences that enhance in-classroom curriculum while expanding student knowledge of international trade.</p>

          <ul className="stats">
            <li className="stat">
              <span className="stat__num line">210</span>
              <span className="stat__txt">Summer High School Internship participants</span>
            </li>
            <li className="stat">
              <span className="stat__num line">$1M</span>
              <span className="stat__txt">Nearly $1 million in scholarships awarded</span>
            </li>
            <li className="stat">
              <span className="stat__num line">460</span>
              <span className="stat__txt">Academy of Global Logistics students in four-year pathway</span>
            </li>
          </ul>
        </div>
      </section>
    );
  }
}

export default EducationInfo;