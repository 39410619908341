import React from "react";
import { Link } from "gatsby";

import careers from "src/images/community/card-education-careers-4.jpg"; 
import internships from "src/images/community/card-education-internships-3.jpg";
import programs from "src/images/community/card-education-programs-4.jpg";
import scholarships from "src/images/community/card-education-scholarships-4.jpg";
import teachers from "src/images/community/card-education-teachers-4.jpg";

/**
 *  Cards on Education main page
 */
class EducationCards extends React.Component {
  render() {
    return (
      <section className="cards gutter education-cards-section">
        <h2 className="heading heading--med line">Explore Education Resources</h2>
        <p className="intro">Find a career, apply for an internship or scholarship, and find out more about the Port’s educational programs.</p>

        <ul className="card card--rect-5">
          <li>
            <Link to="/community/education-resources-careers/">
              <div className="card__photo-txt">
                <img src={careers} alt="" />
                <p className="card__title">Careers</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/community/education-resources-internships/">
              <div className="card__photo-txt">
                <img src={internships} alt="" />
                <p className="card__title">Internships</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/community/education-resources-scholarships/">
              <div className="card__photo-txt">
                <img src={scholarships} alt="" />
                <p className="card__title">Scholarships</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/community/education-resources-teachers/">
              <div className="card__photo-txt">
                <img src={teachers} alt="" />
                <p className="card__title">Teachers</p>
              </div>
            </Link>
          </li>
          <li>
            <Link to="/community/education-resources-programs/">
              <div className="card__photo-txt">
                <img src={programs} alt="" />
                <p className="card__title">Programs</p>
              </div>
            </Link>
          </li>
        </ul>
      </section>
    );
  }
}

export default EducationCards;
